.App {
  text-align: center;
}

.App-logo {
  max-height: 120px;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 0.8s linear;
  }
}

.background {
  background-color: #282c34;
  flex-direction: row;
}

.center-all{
  text-align: center;
  align-items: center;
  justify-content: center;
}

.text-white{
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.Image-big{
  object-fit: contain;
  width: 80%;
  height: auto;
}

body {
  background-color: #282c34;
}

.NavLink{
  font-size: calc(10px + 2vmin);
  color: lime;
  float: left;
  padding-left: 25px;
  padding-right: 25px;
}


.Doggy {
  padding: 6px;
  position: relative;
  min-width: 300px;
  max-width: 800px;
  min-height: 200px;
  max-height: 600px;
}

.Doggy-text-top{
  position: absolute;
  font-size: calc(20px + 1vmin);
  color: hsl(56, 100%, 50%);
  left: 0;
  text-align:center;
  width: 100%;
  z-index: 1;
}
.Doggy-text-bot{
  position: absolute;
    left: 0%;
    right: 0%;
    transform: translate(-0%, 423%);
    display: block;
    margin: auto; 
  max-width: 300px;
  z-index: 2;
}

.Doggy-img{
  max-width: 500px;
  max-height: 300px;
  z-index: -1;
}
.Doggy-part{
  width: 500px;
  height: 300px;
}

.rowC{
  justify-content: center;
  margin: auto;
  width: auto;
  display:flex;
  flex-direction:row;
  flex-wrap: wrap;
}
.vertical{
  display:flex;
  flex-direction:column;
  margin: 6px;
}
.YAER{
  display:inline-block;
    margin:5px 5px;
}

.QR{
  display: inline-block;
}
.img-YAER{
  max-height:500px;
  max-width: 600px;
}
.img-QR{
  max-height:500px;
  max-width: 50%;
  image-rendering: pixelated
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
